import React, { useEffect } from 'react';
import { SwitchTransition } from 'react-transition-group';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Show } from 'wui/Show';
import { Fade } from 'wui/Fade';
import { Drawer, type IDrawerProps } from 'wui/Drawer';
import { DialogContainer } from 'wui/DialogContainer';
import { DialogTitle } from 'wui/DialogTitle';
import { Tabs } from 'wui/Tabs';

import { ApplicationsTab, DetailsTab, ModerateTab } from './Tabs';

export type IGroupSettingsProps = {
  isOpen: boolean;
  groupId: string;
  activeTab?: string;
  biExtra?: Record<string, any>;
  onClose(): void;
} & Partial<IDrawerProps>;

export function GroupSettings(props: IGroupSettingsProps) {
  const {
    groupId,
    isOpen,
    biExtra,
    onClose,
    activeTab: activeTabProp,
    ...rest
  } = props;

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState(activeTabProp);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab('details');
    }
  }, [isOpen]);

  useEffect(() => {
    if (activeTabProp) {
      setActiveTab(activeTabProp);
    }
  }, [activeTabProp]);

  return (
    <Drawer
      hideCloseButton={false}
      isOpen={isOpen}
      onClose={handleClose}
      anchor="right"
      aria-labelledby="gs-dialog-title"
      {...rest}
    >
      <DialogContainer>
        <DialogTitle
          id="gs-dialog-title"
          title={t('groups-web.group.settings')}
        />

        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={setActiveTab}
          skin="fullUnderline"
          data-hook="group-settings-tabs"
          items={[
            {
              id: 'details',
              title: t('groups-web.group.settings.details'),
            },
            {
              id: 'apps',
              title: t('groups-web.group.settings.tabs'),
            },
            {
              id: 'settings',
              title: t('groups-web.group.settings.moderate'),
            },
          ]}
        />

        <SwitchTransition>
          <Fade key={activeTab}>
            <>
              <Show if={activeTab === 'details'}>
                <DetailsTab
                  biExtra={biExtra}
                  groupId={groupId}
                  onClose={handleClose}
                />
              </Show>

              <Show if={activeTab === 'apps'}>
                <ApplicationsTab groupId={groupId} onClose={handleClose} />
              </Show>

              <Show if={activeTab === 'settings'}>
                <ModerateTab groupId={groupId} />
              </Show>
            </>
          </Fade>
        </SwitchTransition>
      </DialogContainer>
    </Drawer>
  );

  function handleClose() {
    onClose();
  }
}

GroupSettings.displayName = 'GroupSettings';
