import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { AllowPolicy } from '@wix/ambassador-social-groups-v2-group/types';
import { memberPermissionsEditSaveSrc111Evid531 } from '@wix/bi-logger-groups-data/v2';

import { Show } from 'wui/Show';
import { Button } from 'wui/Button';
import { Drawer } from 'wui/Drawer';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogActions } from 'wui/DialogActions';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';
import { SectionNotification } from 'wui/SectionNotification';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import {
  selectGroup,
  selectIsGroupUpdating,
  selectIsSiteAdmin,
} from 'store/selectors';

import { type MemberPermissionsFormValues } from './types';
import { selectMemberPermissionsFormValues } from './selectors';
import { MemberPermissionsForm } from './MemberPermissionsForm';

interface IProps extends React.ComponentProps<typeof Drawer> {
  groupId: string;
}

export function MemberPermissionsDialog(props: IProps) {
  const { groupId, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { group$ } = useController();

  const isAdmin = useSelector(selectIsSiteAdmin);
  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const form = useFormik({
    onSubmit: handleSubmit,
    initialValues: selectMemberPermissionsFormValues(group.settings!),
  });

  const hasProtected = useMemo(() => {
    return Object.values(form.values).some(
      (value) => value === AllowPolicy.OWNER,
    );
  }, [form.values]);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
      form.resetForm();
      form.setSubmitting(false);
    }
  }, [isUpdating]);

  return (
    <Drawer
      anchor="right"
      hideCloseButton={false}
      aria-labelledby="mp-dialog-title"
      {...rest}
    >
      <DialogContainer>
        <DialogTitle
          id="mp-dialog-title"
          title={t('groups-web.group.settings.permissions')}
        />

        <Show if={!isAdmin && hasProtected}>
          <SectionNotification type="alert">
            <SectionNotification.Text>
              {t(
                'groups-web.group.settings.permissions-form.no-permissions.alert',
              )}
            </SectionNotification.Text>
          </SectionNotification>
        </Show>

        <DialogContent>
          <MemberPermissionsForm form={form} />
        </DialogContent>

        <DialogActions>
          <Button variant="basic" outlined fullWidth onClick={props.onClose}>
            {t('groups-web.cancel')}
          </Button>

          <Button
            fullWidth
            variant="basic"
            onClick={handleSaveClick}
            loading={form.isSubmitting}
            disabled={!form.dirty || form.isSubmitting}
          >
            {t('groups-web.save')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </Drawer>
  );

  function handleSubmit(values: MemberPermissionsFormValues) {
    group$.updateGroupSettings(groupId, values);
    bi.report(
      memberPermissionsEditSaveSrc111Evid531({
        settings_changed: JSON.stringify(values),
      }),
    );
  }

  function handleSaveClick() {
    form.setSubmitting(true);
    form.submitForm();

    bi.report(
      groupSettingsSave({
        group_id: groupId,
        origin: 'save_button',
        tabName: 'permissions',
      }),
    );
  }
}

MemberPermissionsDialog.displayName = 'MemberPermissionsDialog';
