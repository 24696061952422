import React from 'react';
import cls from 'classnames';
import { SwitchTransition } from 'react-transition-group';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';
import {
  ErrorMonitorBoundary,
  Trans,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { Container, type IContainerProps } from 'wui/Container';
import { ErrorState } from 'wui/ErrorState';
import { Fade } from 'wui/Fade';

import { ROUTES } from './constants';

import classes from './ApplicationPage.scss';

export function ApplicationPage(props: IContainerProps) {
  const { className, ...rest } = props;
  const { state } = useCurrentStateAndParams();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const router = useRouter();

  const active = ROUTES.find((item) => state?.name?.includes(item.state));
  const Page = active?.component || null;

  return (
    <SwitchTransition>
      <Fade key={state.name}>
        <Container
          as="section"
          role="region"
          fluid={isMobile}
          aria-labelledby={state.data?.application}
          className={cls(className, classes.root, {
            [classes.mobile]: isMobile,
          })}
          {...rest}
        >
          <ErrorMonitorBoundary
            fallback={
              <ErrorState
                wired
                title={t('groups-web.discussion.error-state.title')}
                subtitle={
                  <Trans
                    i18nKey={t(
                      'groups-web.discussion.error-state.owner.message',
                    )}
                    components={[
                      <span key={0} />,
                      <br key={1} />,
                      <span key={2} />,
                    ]}
                  />
                }
                actionLabel={t('groups-web.discussion.error-state.cta')}
                onRetry={handleRetry}
              />
            }
          >
            {Page ? <Page data-hook={active?.dataHook} /> : null}
          </ErrorMonitorBoundary>
        </Container>
      </Fade>
    </SwitchTransition>
  );

  function handleRetry() {
    router.reload();
  }
}

ApplicationPage.displayName = 'ApplicationPage';
